import React, { useState, useEffect } from "react";
import {
  Container, Grid, TextField, Select, MenuItem, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel,
  FormControl, Typography, Box, Divider
} from "@mui/material";
import { apiService } from "../../../services/Service";
import AuthorStatsChart from "../../../components/AuthorStatsChart";


interface Author {
  _id: string;
  fullname: string;
}

interface Contribution {
  _id: string;
  totalAuthored: number;
  easyAuthored: number;
  mediumAuthored: number;
  hardAuthored: number;
}

interface AuthorData {
  totalAuthored: number;
  easyAuthored: number;
  mediumAuthored: number;
  hardAuthored: number;
  reviewStatus: Record<string, number>;
  tagDistribution: Record<string, number>;
}

const AuthorContributions = () => {
  const [authors, setAuthors] = useState<Author[]>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [contributions, setContributions] = useState<Contribution | null>(null);
  const [authorData, setAuthorData] = useState<AuthorData | null>(null);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response: any = await apiService.get("/admin/author/all");
        setAuthors(response.authors);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };
    fetchAuthors();
  }, []);

  useEffect(() => {
    const fetchContributions = async () => {
      setContributions(null);
      setAuthorData(null);
      if (!selectedAuthor) return;

      try {
        const queryParams = new URLSearchParams({
          authorId: selectedAuthor,
          startDate: startDate || "",
          endDate: endDate || "",
        });

        const response: any = await apiService.get(`/admin/author/contributions?${queryParams}`);

        setAuthorData({
          ...response.contributions,
          reviewStatus: response.reviewStatus,
          tagDistribution: response.tagDistribution
        });
        setContributions(response.contributions);

      } catch (error) {
        console.error("Error fetching contributions:", error);
      }
    };

    fetchContributions();
  }, [selectedAuthor, startDate, endDate]);

  return (
    <Container>
      <Typography variant="h4" fontWeight="bold" gutterBottom>Author Contributions</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Author</InputLabel>
            <Select
              value={selectedAuthor}
              onChange={(e) => setSelectedAuthor(e.target.value)}
            >
              <MenuItem value="">Select Author</MenuItem>
              {authors.map((author) => (
                <MenuItem key={author._id} value={author._id}>
                  {author.fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="date" label="Start Date" InputLabelProps={{ shrink: true }} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="date" label="End Date" InputLabelProps={{ shrink: true }} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </Grid>
      </Grid>

      {contributions && (
        <>
          <TableContainer component={Paper} sx={{ marginTop: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Author</TableCell>
                  <TableCell>Total Questions</TableCell>
                  <TableCell>Easy</TableCell>
                  <TableCell>Medium</TableCell>
                  <TableCell>Hard</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{authors.find(a => a._id === selectedAuthor)?.fullname || "Unknown"}</TableCell>
                  <TableCell>{contributions.totalAuthored}</TableCell>
                  <TableCell>{contributions.easyAuthored}</TableCell>
                  <TableCell>{contributions.mediumAuthored}</TableCell>
                  <TableCell>{contributions.hardAuthored}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box mt={4}>
           {contributions?.totalAuthored > 0 && <AuthorStatsChart authorData={authorData!} />}
          </Box>
        </>
      )}

      {!selectedAuthor && <Typography variant="h5" align="center" my={2}>Select an author to view contributions</Typography>}
      {selectedAuthor && !contributions && <Typography my={2} variant="h5" align="center">No contributions found</Typography>}
    </Container>
  );
};

export default AuthorContributions;
