import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeContextProvider from "./contextApi/ThemeContext";
import Login from "./pages/Login";
import NotFoundPage from "./components/404Page";
import LandingPage from "./components/LandingPage"; // Import the landing page
import Dashboard from "./components/dashboards/BaseDashBoard"; // Common dashboard layout
import ProfileDashboard from "./pages/Profile";
import UserManagement from "./pages/admin/users/users";
import Problems from "./pages/author/problem/problems";
import CreateProblem from "./pages/author/problem/createProblem";
import CreateMCQ from "./pages/author/mcq/createMcq";
import MCQs from "./pages/author/mcq/problems";
import ClientList from "./pages/admin/client/ManageClients";
import CreateClient from "./pages/admin/client/CreateClient";
import UpdateClient from "./pages/admin/client/UpdateClient";
import UpdateMCQ from "./pages/author/mcq/updateMcq";
import UpdateProblem from "./pages/author/problem/updateProblem";
import ClientDetails from "./pages/client/ClientDetails";
import ClientUserManagement from "./pages/client/UserManagement";
import Assessments from "./pages/assessment/Assessments";
import ClientAssessments from "./pages/client/assessment/ClientAssessments";
import CreateAssessment from "./pages/assessment/CreateAssessment";
import ProblemPage from "./pages/problem/ProblemPage";
import UpdateAssessment from "./pages/assessment/UpdateAssessment";
import CreateUser from "./pages/admin/users/CreateUser";
import AssessmentDetailsPage from "./pages/assessment/AssessmentEnv/AssessmentDetailsPage";
import AssessmentHomePage from "./pages/assessment/AssessmentEnv/AssessmentHomePage";
import ManagePlans from "./pages/admin/plans/ManagePlans";
import CreatePlan from "./pages/admin/plans/CreatePlan";
import UpdatePlan from "./pages/admin/plans/UpdatePlan";
import SubscribeToPlan from "./components/SubscribeToPlan";
import PaymentSuccess from "./components/PaymentSuccess";
import UserGroupManagement from "./pages/client/userGroupMangement";
import ProctorApp from "./components/Proctoring/ProctorApp";
import CreateUsersTabs from "./pages/client/CreateUsersTabs";
import EyeTrackingComponent from "./components/Proctoring/EyeTrackingComponent";
import TestApp from "./components/Test";
import Tags from "./pages/admin/tags/mcq/Tags";
import Createtags from "./pages/admin/tags/mcq/Createtags";
import Updatetags from "./pages/admin/tags/mcq/Updatetags";
import UserPage from "./pages/admin/users/UserPage";
import ReviewMcqs from "./pages/moderator/ViewAllMcqs"
import ReviewMcq from "./pages/moderator/ReviewMcq";
import AuthorContributions from "./pages/admin/author/AuthorContributions";



const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* Landing Page Route */}
            <Route path="/" element={<LandingPage />} />

            {/* Protected Routes (only those that need the dashboard layout) */}
            <Route element={<Dashboard />}>
              <Route path="profile" element={<ProfileDashboard />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="users/create" element={<CreateUser />} />
              <Route path="coding-problems" element={<Problems />} />
              <Route path="coding-problems/create" element={<CreateProblem />} />
              <Route path="mcq-problems" element={<MCQs />} />
              <Route path="mcq-problems/create" element={<CreateMCQ />} />
              <Route path="mcq/update/:mcqId" element={<UpdateMCQ />} />
              <Route path="mcq/review/:mcqId" element={<ReviewMcq />} />
              <Route path="clients" element={<ClientList />} />
              <Route path="clients/create" element={<CreateClient />} />
              <Route path="problem/update/:problemId" element={<UpdateProblem />} />
              <Route path="admin/client/update/:id" element={<UpdateClient />} />
              <Route path="client/details" element={<ClientDetails />} />
              <Route path="client/assessments" element={<Assessments />} />
              <Route path="client/users" element={<ClientUserManagement />} />
              <Route path="client/user-groups" element={<UserGroupManagement />} />
              <Route path="client/create-users" element={<CreateUsersTabs />} />
              <Route path="client/assessments/create" element={<CreateAssessment />} />
              <Route path="assessments" element={<Assessments />} />
              <Route path="assessments/create" element={<CreateAssessment />} />
              <Route path="assessments/update/:id" element={<UpdateAssessment />} />
              <Route path="plans" element={<ManagePlans />} />
              <Route path="plans/create" element={<CreatePlan />} />
              <Route path="plans/update/:id" element={<UpdatePlan />} />
              <Route path="mcq-problems/review" element={<ReviewMcqs />} />
              <Route path="mcq-tags" element={<Tags />} />
              <Route path="mcq-tags/create" element={<Createtags />} />
              <Route path="mcq-tags/update/:id" element={<Updatetags />} />
              <Route path="admin/user/:id" element={<UserPage />} />
              <Route path="work-tracker" element={<AuthorContributions />} />


            </Route>
            <Route path="/problem/slug/:slug" element={<ProblemPage />} />

            {/* Login Route */}
            <Route path="/login" element={<Login />} />
            <Route path="/assessment/details/:slug" element={<AssessmentDetailsPage />} />
            <Route path="/assessment/:assessmentslug" element={<AssessmentHomePage />} />

            <Route path="/subscribe/plan/:id" element={<SubscribeToPlan />} /> {/* New route for SubscribeToPlan}
             <Route path="/paymentsuccess" element={<PaymentSuccess />} />

            {/* Catch-all Route for Not Found */}
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route path="/proctor" element={<ProctorApp />} />
            <Route path="/eye-tracking" element={<EyeTrackingComponent />} />
            <Route path="/test" element={<TestApp />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
