import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  SelectChangeEvent,
  Badge,
  Avatar,
  Button,
  Tooltip,
  ListSubheader,
} from "@mui/material";
import { Link } from "react-router-dom";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../utils/types";
import { handleAuthError } from "../../../utils/authUtils";
import useDebounce from "../../../customHooks/useDebounce";
import { FaEdit } from "react-icons/fa";
import { stat } from "fs";
import { useUser } from "../../../contextApi/UserContext";

interface Section {
  section: string;
  tags: string[];
}

interface Author {
  _id: string;
  fullname: string;
}

const MCQs: React.FC = () => {
  const [mcqs, setMCQs] = useState<any[]>([]); // Replace `any` with your MCQ type
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [difficulty, setDifficulty] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [totalMCQs, setTotalMCQs] = useState<number>(0);
  const [tagOptions, setTagOptions] = useState<Section[]>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<string>("");
  const [authors, setAuthors] = useState<Author[]>([]);
  const [status, setStatus] = useState<string>("");
  const { user } = useUser();
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response: any = await apiService.get("/admin/author/all");
        setAuthors(response.authors);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };
    if (user?.role === "admin") fetchAuthors();
  }, []);

  useEffect(() => {
    const fetchMCQs = async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams({
          page: page.toString(),
          limit: rowsPerPage.toString(),
          search: debouncedSearch,
          difficulty,
          status,
          tags: selectedTags.join(","),
          ...(user && user.role === "admin" && { author: selectedAuthor }) // Conditionally include author
        }).toString();
        
        const response = await apiService.get<{
          mcqs: any[]; // Replace with your MCQ type
          totalMCQs: number;
        }>(`/mcq?${params}`);
        setMCQs(response.mcqs);
        setTotalMCQs(response.totalMCQs);
      } catch (err) {
        setError("Failed to fetch MCQs");
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMCQs();
  }, [page, rowsPerPage, debouncedSearch, difficulty, selectedTags, status,selectedAuthor]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleDifficultyChange = (event: SelectChangeEvent<string>) => {
    setDifficulty(event.target.value);
  };

  const handleTagChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedTags(event.target.value as string[]);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const fetchSections = () => {
    setLoading(true);
    apiService
      .get(`/tags/mcq/sections`) // Replace this endpoint with your actual backend endpoint
      .then((response: any) => {
        // Set the sections with their tags
        setTagOptions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchSections();
  }, []);


  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );

  return (
    <Box p={3}>
      <Box mb={2} display="flex" gap={2} flexWrap="wrap" alignItems="center">
        {user?.role === "admin" && <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Author</InputLabel>
          <Select
            value={selectedAuthor}
            onChange={(e) => setSelectedAuthor(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {authors.map((author) => (
              <MenuItem key={author._id} value={author._id}>
                {author.fullname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Difficulty</InputLabel>
          <Select
            value={difficulty}
            onChange={handleDifficultyChange}
            label="Difficulty"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="easy">Easy</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="hard">Hard</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value as string)}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="underreview">Under Review</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ flex: 2, minWidth: 200 }}>
          <InputLabel>Tags</InputLabel>
          <Select
            name="tags"
            multiple
            value={selectedTags}
            onChange={(e: SelectChangeEvent<string[]>) => {
              const { value } = e.target;
              setSelectedTags(value as string[]);
            }}
            label="Tags"
            renderValue={(selected) => (selected as string[]).join(", ")}
          >
            {tagOptions.flatMap((section: Section) => [
              <ListSubheader key={`${section.section}-header`}>{section.section}</ListSubheader>,
              ...section.tags.map((tag: string) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Difficulty</TableCell>
              {user?.role !== 'author' && <TableCell>Author</TableCell>}
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mcqs?.map((mcq) => (
              <TableRow key={mcq.id}>
                {" "}
                {/* Replace with your unique identifier */}
                <TableCell>
                  <Tooltip
                    title={
                      <div dangerouslySetInnerHTML={{ __html: mcq.question }} />
                    }
                    placement="top-start"
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      <div dangerouslySetInnerHTML={{
                        __html: mcq.question.length > 40
                          ? mcq.question.substring(0, 40) + '...'
                          : mcq.question
                      }} />
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {mcq.tags.map((tag: string) => (
                      <Chip
                        key={tag}
                        label={tag}
                        color="primary"
                        size="small"
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell sx={{ textTransform: "capitalize", textAlign: "center" }}>
                  <Badge
                    badgeContent={mcq.difficultyLevel}
                    color={
                      mcq.difficultyLevel === "easy"
                        ? "success"
                        : mcq.difficultyLevel === "medium"
                          ? "warning"
                          : "error"
                    }
                  />
                </TableCell>
                {user?.role !== 'author' && <TableCell>{mcq?.author?.fullname}</TableCell>}
                <TableCell sx={{ textTransform: "capitalize", textAlign: "center" }}>
                  <Badge
                    badgeContent={mcq?.status}
                    color={
                      mcq?.status === "pending"
                        ? "warning"
                        : mcq?.status === "underreview"
                          ? "info"
                          : mcq?.status === "accepted"
                            ? "success"
                            : mcq?.status === "rejected"
                              ? "error"
                              : "default"
                    }
                  />
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/mcq/update/${mcq._id}`} // Use dynamic routing to the MCQ page
                    variant="contained"
                    color="primary"
                    style={{ textDecoration: "none", color: "inherit" }}
                    endIcon={<FaEdit />}
                    disabled={mcq?.status === "accepted" && user?.role !== "admin"}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ color: "white" }}

                    >
                      Edit
                    </Typography>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalMCQs}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default MCQs;
