import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { handleAuthError } from "../../../utils/authUtils";
import {
  Box,
  CircularProgress,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Grid,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import {
  AccessTime,
  CheckCircleOutline,
  ErrorOutline,
  Timer,
} from "@mui/icons-material";
import RenderQuestions from "./RenderQuestions";
import CodingProblemRender from "./CodingProblemRender";
import McqProblemRenderer from "./McqProblemRenderer";
import { formatTime } from "../../../utils/helperFunctions";
import { uploadResponsesToS3 } from "./helpers/uploadResponsesToS3";
import useBaseProctoring from "../../../components/Proctoring/UseBaseProctoring";
import { useAdvanceProctoring } from "../../../components/Proctoring/UseAdvanceProctoring";

const MemoizedCodingProblemRender = React.memo(CodingProblemRender);
const MemoizedMcqProblemRenderer = React.memo(McqProblemRenderer);
const MemoizedRenderQuestions = React.memo(RenderQuestions);

const hiddenVideoStyle: React.CSSProperties = {
  position: "fixed",
  top: "-10000px",
  left: "-10000px",
  width: "10px",
  height: "auto",
  opacity: 0,
  pointerEvents: "none",
};

declare global {
  interface Window {
    mediaRecorder: MediaRecorder;
    mediaStream: MediaStream;
  }
}

const AssessmentHomePage = () => {
  const { assessmentslug } = useParams<{ assessmentslug: string }>();
  const [assessmentData, setAssessmentData] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);
  const [assessmentEnded, setAssessmentEnded] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [userMcqResponse, setUserMcqResponse] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [proctoringPermissionsGranted, setProctoringPermissionsGranted] = useState(false);
  const [isProctoredAssessment, setIsProctoredAssessment] = useState(false);
  const [videoGranted, setVideoGranted] = useState(false);
  const [fullscreenGranted, setFullscreenGranted] = useState(false);
  const [showExitWarning, setShowExitWarning] = useState(false);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  // Proctoring hooks
  const baseProctoringResult = useBaseProctoring({
    onTabSwitch: useCallback(() => {
      if (isProctoredAssessment) {
        alert("Tab switch detected. Please stay on the assessment tab.");
      }
    }, [isProctoredAssessment]),
  });
  const advanceProctoringResult = useAdvanceProctoring();

  const { tabSwitches } = isProctoredAssessment ? baseProctoringResult : { tabSwitches: 0 };
  const { stats, loading: proctoringLoading, videoRef, canvasRef } = isProctoredAssessment
    ? advanceProctoringResult
    : { stats: {}, loading: false, videoRef: null, canvasRef: null };

  const requestPermissions = useCallback(async () => {
    if (timer <= 0) {
      console.log("Permissions request skipped because timer is not active.");
      return;
    }
    try {
      let videoGrantedLocal = false;
      let fullscreenGrantedLocal = false;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoGrantedLocal = true;
        stream.getTracks().forEach((track) => track.stop());
      } catch (videoError) {
        console.error("Video permission not granted:", videoError);
      }

      fullscreenGrantedLocal = document.fullscreenElement !== null;
      if (!fullscreenGrantedLocal && document.documentElement.requestFullscreen) {
        try {
          await document.documentElement.requestFullscreen();
          fullscreenGrantedLocal = true;
        } catch (fullscreenError) {
          console.error("Fullscreen permission not granted:", fullscreenError);
        }
      }

      if (videoGrantedLocal && fullscreenGrantedLocal) {
        setProctoringPermissionsGranted(true);
      } else {
        throw new Error("Required permissions not fully granted.");
      }
    } catch (error) {
      console.error("Error requesting permissions:", error);
      alert("Permissions are required to proceed with the assessment.");
    }
  }, [timer]);

  const handleRequestPermissions = useCallback(async () => {
    await requestPermissions();
    setVideoGranted(true);
    setFullscreenGranted(document.fullscreenElement !== null);
  }, [requestPermissions]);

  const handleVisibilityChange = useCallback(() => {
    if (tabSwitchCount > 0 && tabSwitchCount < 3) {
      alert("If you switch tabs, the assessment will be submitted automatically.");
    }
    if (document.visibilityState === "hidden" && timer > 0) {
      setTabSwitchCount((prev) => prev + 1);
    }
  }, [tabSwitchCount, timer]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [handleVisibilityChange]);

  useEffect(() => {
    if (tabSwitchCount > 2 && timer > 0) {
      onSubmitAssessment();
    }
  }, [tabSwitchCount, timer]);

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setVideoGranted(true);
      } catch {
        setVideoGranted(false);
      }
      setFullscreenGranted(document.fullscreenElement !== null);
    };

    if (timer > 0 && isProctoredAssessment) {
      checkPermissions();
    }

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && !showExitWarning) {
        setShowExitWarning(true);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && document.fullscreenElement) {
        event.preventDefault();
        setShowExitWarning(true);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [timer, isProctoredAssessment, showExitWarning]);

  const handleExitWarningAgree = useCallback(() => {
    setShowExitWarning(false);
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
  }, []);

  const handleExitWarningDisagree = useCallback(() => {
    setShowExitWarning(false);
    if (document.fullscreenElement) {
      document.exitFullscreen()
        .then(() => onSubmitAssessment())
        .catch((error) => console.error("Error exiting fullscreen:", error));
    } else {
      onSubmitAssessment();
    }
  }, []);

  // Upload responses periodically (every 5 minutes)
  useEffect(() => {
    const interval = setInterval(() => {
      const lastUploadTime = parseInt(localStorage.getItem("lastUploadTime") || "0", 10);
      const now = Date.now();
      if (now - lastUploadTime >= 5 * 60 * 1000) {
        const responses = JSON.parse(localStorage.getItem("userMcqResponse") || "[]");
        uploadResponsesToS3(presignedUrl, responses);
        localStorage.setItem("lastUploadTime", now.toString());
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [presignedUrl]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await apiService.get(`/assessments/questions/${assessmentslug}`);
        setPresignedUrl(response.data.presignedUrl);
        const {
          startTime: starttime,
          endTime: endtime,
          duration,
          isProctored,
          randomizeQuestions,
        } = response.data.details;
        const startTime = new Date(starttime).getTime();
        const endTime = new Date(endtime).getTime();
        const currentTime = Date.now();
        const durationInMs = duration * 60 * 1000;

        const storedStart = localStorage.getItem(`assessment_start_${assessmentslug}`);
        const userStartTime = storedStart ? parseInt(storedStart, 10) : currentTime;
        if (!storedStart) {
          localStorage.setItem(`assessment_start_${assessmentslug}`, userStartTime.toString());
        }

        if (currentTime < startTime) {
          setTimer((startTime - currentTime) / 1000);
          setTimerRunning(false);
        } else if (currentTime >= startTime && currentTime < endTime) {
          const maxEndTime = Math.min(userStartTime + durationInMs, endTime);
          setTimer((maxEndTime - currentTime) / 1000);
          setTimerRunning(true);
        } else {
          setTimer(0);
          setTimerRunning(false);
          setAssessmentEnded(true);
        }
        if (randomizeQuestions) {
          response.data.details.questions = response.data.details.questions.sort(() => Math.random() - 0.5);
          response.data.mcq = response.data.mcq.sort(() => Math.random() - 0.5);
          response.data.coding = response.data.coding.sort(() => Math.random() - 0.5);
        }

        setAssessmentData(response.data);
        setSelectedQuestion(
          response.data.coding.length > 0
            ? response.data.coding[0]
            : response.data.mcq[0]
        );
        if (["mcq", "mixed"].includes(response.data.details.type)) {
          const mcqs = response.data.details.questions.filter((q: any) => q.type === "mcq");
          const initialResponses = mcqs.map((mcq: any) => ({
            mcqId: mcq.questionId,
            selectedOption: "",
          }));
          if (!userMcqResponse.length) {
            setUserMcqResponse(initialResponses);
            localStorage.setItem("userMcqResponse", JSON.stringify(initialResponses));
          }
        }
        if (isProctored) {
          setIsProctoredAssessment(true);
        }
        setLoading(false);
      } catch (error: any) {
        if (error instanceof AxiosError) {
          handleAuthError({ error: error.response?.data.error }, window.location.href);
        }
        setErrorMessage(error.response?.data.error || "Failed to fetch assessment data");
        setLoading(false);
      }
    };
    fetchData();
  }, [assessmentslug, userMcqResponse]);

  // Timer interval
  useEffect(() => {
    if (timerRunning && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer <= 0) {
      setTimerRunning(false);
    }
  }, [timerRunning, timer]);

  useEffect(() => {
    if (timer < 0) {
      onSubmitAssessment();
    }
    setAssessmentEnded(timer <= 0);
  }, [timer]);

  const renderQuestionContent = useMemo(() => {
    if (!selectedQuestion) {
      return <Typography>Select a question to begin.</Typography>;
    }
    if (assessmentData?.coding.includes(selectedQuestion)) {
      return <MemoizedCodingProblemRender slug={selectedQuestion.slug} />;
    }
    if (assessmentData?.mcq.includes(selectedQuestion)) {
      return (
        <MemoizedMcqProblemRenderer
          selectedQuestion={selectedQuestion}
          userMcqResponse={userMcqResponse}
        />
      );
    }
    return null;
  }, [selectedQuestion, assessmentData, userMcqResponse]);

  // Persist MCQ responses on change
  useEffect(() => {
    localStorage.setItem("userMcqResponse", JSON.stringify(userMcqResponse));
  }, [userMcqResponse]);

  const submitAssessment = useCallback(async () => {
    const submitData = {
      assessmentId: assessmentData.details._id,
      mcqResponses: JSON.parse(localStorage.getItem("userMcqResponse") || "[]"),
    };
    try {
      await apiService.post(`/assessments/submit`, submitData);
      setAssessmentEnded(true);
      setErrorMessage("Assessment submitted successfully");
    } catch (error) {
      console.error("Error submitting assessment:", error);
    }
  }, [assessmentData]);

  const clearLocalStorage = useCallback(() => {
    localStorage.removeItem("userMcqResponse");
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("mcq") || key.startsWith("assessment_start")) {
        localStorage.removeItem(key);
      }
    });
  }, []);

  const onSubmitAssessment = useCallback(async () => {
    try {
      if (window.mediaRecorder && window.mediaRecorder.state === "recording") {
        window.mediaRecorder.stop();
        console.log("Video recording stopped.");
        if (window.mediaStream) {
          window.mediaStream.getTracks().forEach((track) => track.stop());
          console.log("Media stream tracks stopped.");
        }
      }
      handleClose();
      await submitAssessment();
      clearLocalStorage();
    } catch (error) {
      console.error("Error during assessment submission:", error);
    }
  }, [clearLocalStorage, handleClose, submitAssessment]);

  // Proctoring violation checks
  useEffect(() => {
    if (isProctoredAssessment) {
      if ((stats.persons ?? 0) > 1 || (stats.faces ?? 0) > 1 || stats.cellPhoneDetected) {
        alert("Proctoring violation detected: Multiple persons, faces, or cellphone usage.");
        onSubmitAssessment();
      }
    }
  }, [stats, isProctoredAssessment, onSubmitAssessment]);

  useEffect(() => {
    if (isProctoredAssessment && tabSwitches > 0 && !assessmentEnded) {
      alert(`Tab switch detected. Please stay on the assessment tab. Tab switches: ${tabSwitches}`);
      if (tabSwitches > 2) {
        onSubmitAssessment();
      }
    }
  }, [tabSwitches, isProctoredAssessment, assessmentEnded, onSubmitAssessment]);

  // Render logic
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h4" gutterBottom>
          {errorMessage}
        </Typography>
      </Box>
    );
  }

  if (assessmentEnded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h4" gutterBottom>
          Assessment has ended
        </Typography>
      </Box>
    );
  }

  if (isProctoredAssessment && !proctoringPermissionsGranted) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5" gutterBottom>
          Proctored Assessment - Grant Required Permissions
        </Typography>
        {!videoGranted && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Camera access is required to proceed.
          </Alert>
        )}
        {!fullscreenGranted && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Fullscreen mode is required to proceed.
          </Alert>
        )}
        <Button variant="contained" color="primary" onClick={handleRequestPermissions}>
          Grant Permissions
        </Button>
      </Box>
    );
  }

  return (
    <Box display="flex">
      <video ref={videoRef} autoPlay playsInline style={hiddenVideoStyle} />
      <canvas ref={canvasRef} style={hiddenVideoStyle} />
      {/* Left Panel */}
      <Drawer
        anchor="left"
        open
        variant="permanent"
        sx={{
          width: 280,
          "& .MuiDrawer-paper": {
            width: 280,
            boxSizing: "border-box",
          },
        }}
      >
        <Box p={1}>
          <MemoizedRenderQuestions
            assessmentData={assessmentData}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        </Box>
      </Drawer>
      {/* Right Panel */}
      <Box p={0} flex={2} justifyContent="space-between">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: 1, backgroundColor: "background.paper" }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
              {assessmentData.details.name}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item display="flex" alignItems="center" gap={1}>
                <AccessTime sx={{ color: "success.main" }} />
                <Typography variant="body2" color="text.secondary">
                  Start: {new Date(assessmentData.details.startTime).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item display="flex" alignItems="center" gap={1}>
                <Typography variant="body2" color="text.secondary">
                  End: {new Date(assessmentData.details.endTime).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" alignItems="center" gap={3}>
            <Button variant="contained" color="primary" onClick={handleOpen} disabled={timer <= 0}>
              Submit Assessment
            </Button>
            <Chip
              label={timer > 0 ? "Ongoing" : "Completed"}
              color={timer > 0 ? "success" : "error"}
              icon={timer > 0 ? <CheckCircleOutline /> : <ErrorOutline />}
              sx={{ fontWeight: "bold", paddingX: 1 }}
            />
            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: "primary.light",
              }}
            >
              <IconButton disabled sx={{ color: "primary.dark" }}>
                <Timer sx={{ fontSize: 30, color: "primary.dark" }} />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "text.primary",
                  width: "120px",
                  textAlign: "center",
                  fontFamily: "monospace",
                }}
              >
                {timer > 0 ? formatTime(timer) : "Time's up!"}
              </Typography>
            </Paper>
          </Box>
        </Box>
        <Paper elevation={2} sx={{ p: 1 }}>
          {renderQuestionContent}
        </Paper>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Submit Assessment?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit the assessment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmitAssessment}>Yes Submit</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showExitWarning} onClose={() => {}} disableEscapeKeyDown>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Typography>
            Exiting fullscreen will automatically submit the assessment. Do you want to stay in fullscreen?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExitWarningAgree} color="primary">
            Stay in Fullscreen
          </Button>
          <Button onClick={handleExitWarningDisagree} color="secondary">
            Exit and Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssessmentHomePage;
