import React from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { PieChart, BarChart } from "@mui/x-charts";

interface ModeratorData {
  totalReviewed: number;
  easyReviewed: number;
  mediumReviewed: number;
  hardReviewed: number;
  reviewStatus: Record<string, number>;
  tagDistribution: Record<string, number>;
}

interface ModeratorStatsChartProps {
  moderatorData: ModeratorData;
}

const ModeratorStatsChart: React.FC<ModeratorStatsChartProps> = ({ moderatorData }) => {
  if (!moderatorData) return null;

  const difficultyData = [
    { id: 0, value: moderatorData.easyReviewed, label: "Easy" },
    { id: 1, value: moderatorData.mediumReviewed, label: "Medium" },
    { id: 2, value: moderatorData.hardReviewed, label: "Hard" },
  ];

  const tagData = Object.entries(moderatorData.tagDistribution || {}).map(([tag, count], index) => ({
    id: index,
    value: count,
    label: tag,
  }));

  const reviewData = Object.entries(moderatorData.reviewStatus || {}).map(([status, count], index) => ({
    id: index,
    value: count,
    label: status,
  }));

  return (
    <Box sx={{ backgroundColor: "background.default", borderRadius: "12px", padding: 3, boxShadow: 1 }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom color="text.secondary">
        Moderator Review Stats
      </Typography>
      <Divider sx={{ mb: 2 }} />
      
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Difficulty Level Distribution */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>MCQs Reviewed by Difficulty</Typography>
          <PieChart series={[{ data: difficultyData, innerRadius: 40 }]} height={250} />
        </Grid>

        {/* MCQ Review Status */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>Review Status</Typography>
          <PieChart series={[{ data: reviewData, innerRadius: 40 }]} height={250} />
        </Grid>
        
        {/* Tag-wise Contribution */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>Tag-wise Reviewed MCQs</Typography>
          <BarChart 
            xAxis={[{ scaleType: "band", data: tagData.map(item => item.label) }]} 
            series={[{ data: tagData.map(item => item.value) }]} 
            height={250} 
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModeratorStatsChart;
