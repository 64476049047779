import React from "react";
import {
  Code,
  HelpOutline,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

interface RenderQuestionsProps {
  assessmentData: any;
  selectedQuestion: any;
  setSelectedQuestion: (question: any) => void;
}

const RenderQuestions: React.FC<RenderQuestionsProps> = ({
  assessmentData,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "background.default",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", color: "text.primary" }}
      >
        Questions
      </Typography>
      <List sx={{ overflowY: "auto", padding: 1 }}>
        {assessmentData.coding.map((question: any, index: number) => (
          <ListItemButton
            key={question._id}
            selected={selectedQuestion?._id === question._id}
            onClick={() => setSelectedQuestion(question)}
            sx={{
              borderRadius: 2,
              marginBottom: 1,
              "&:hover": { backgroundColor: "primary.light" },
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": { backgroundColor: "primary.dark" },
              },
            }}
          >
            <ListItemIcon>
              <Code
                sx={{
                  color: selectedQuestion?._id === question._id ? "white" : "text.secondary",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={`Coding Q${index + 1}`}
              primaryTypographyProps={{
                variant: "body1",
                fontWeight: "bold",
                color: selectedQuestion?._id === question._id ? "white" : "text.primary",
              }}
            />
          </ListItemButton>
        ))}
        {assessmentData.mcq.map((question: any, index: number) => (
          <ListItemButton
            key={question._id}
            selected={selectedQuestion?._id === question._id}
            onClick={() => setSelectedQuestion(question)}
            sx={{
              borderRadius: 2,
              marginBottom: 1,
              "&:hover": { backgroundColor: "primary.light" },
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": { backgroundColor: "primary.dark" },
              },
            }}
          >
            <ListItemIcon>
              <HelpOutline
                sx={{
                  color: selectedQuestion?._id === question._id ? "white" : "text.secondary",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={`MCQ Q${index + 1}`}
              primaryTypographyProps={{
                variant: "body1",
                fontWeight: "bold",
                color: selectedQuestion?._id === question._id ? "white" : "text.primary",
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};

export default React.memo(RenderQuestions);
