import React, { useEffect, useState, useCallback } from "react";
import { Box, CircularProgress, Tabs, Tab } from "@mui/material";
import { apiService } from "../../../services/Service";
import "react-resizable/css/styles.css";
import { AxiosError } from "axios";
import { Description, Timelapse } from "@mui/icons-material";
import { Problem } from "../../../constants/ProblemUtils";
import { ErrorResponse } from "../../../utils/types";
import { handleAuthError } from "../../../utils/authUtils";
import Submissions from "./ProblemSubmissionPage";
import CodeEditor from "../../../components/Editor/CodeEditor";
import ProblemDetails from "./ProblemDetails";

interface CodingProblemRenderProps {
  slug: string;
}

const CodingProblemRender: React.FC<CodingProblemRenderProps> = ({ slug }) => {
  const [problem, setProblem] = useState<Problem | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const fetchProblem = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiService.get<{ problem: Problem }>(`/assessments/problem/${slug}`);
      setProblem(response.problem);
    } catch (err) {
      setError("Failed to fetch problem details");
      const errorObj = err as AxiosError<ErrorResponse>;
      if (errorObj.response?.data) {
        handleAuthError({ error: errorObj.response.data.error }, window.location.href);
      }
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    fetchProblem();
  }, [fetchProblem]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );

  if (!problem) return null;

  return (
    <Box display="flex">
      <Box sx={{ overflow: "auto", width: "50%", height: "90vh", overflowY: "scroll" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="problem details and submissions tabs"
        >
          <Tab label="Description" iconPosition="start" icon={<Description />} />
          <Tab label="Submissions" iconPosition="start" icon={<Timelapse />} />
        </Tabs>
        <Box sx={{ marginTop: 2 }}>
          {tabIndex === 0 && <ProblemDetails problem={problem} />}
          {tabIndex === 1 && <Submissions slug={slug} />}
        </Box>
      </Box>
      <Box sx={{ overflow: "auto", width: "50%", height: "90vh" }}>
        <CodeEditor problem={problem} />
      </Box>
    </Box>
  );
};

export default CodingProblemRender;
