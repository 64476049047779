import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { apiService } from "../../../services/Service";
import { AxiosError } from "axios";
import { Client, ErrorResponse } from "../../../utils/types";
import {
  Container,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { handleAuthError } from "../../../utils/authUtils";
import { Link } from "react-router-dom";

const ClientList: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [totalClients, setTotalClients] = useState(0); // Total count of clients from backend
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [approvalFilter, setApprovalFilter] = useState<
    "all" | "approved" | "unapproved"
  >("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          page: currentPage.toString(),
          limit: rowsPerPage.toString(),
          search: search || "",
          isApproved:
            approvalFilter === "all"
              ? "all"
              : approvalFilter === "approved"
                ? "true"
                : "false",
        });

        const url = `/admin/clients?${queryParams.toString()}`;
        const response: any = await apiService.get<{ users: Client[]; totalUsers: number }>(
          url
        );
        setClients(response.users);
        setTotalClients(response.totalUsers); // Use total clients count from backend
      } catch (err) {
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, [currentPage, search, approvalFilter, rowsPerPage]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleApprovalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApprovalFilter(e.target.value as "all" | "approved" | "unapproved");
    setCurrentPage(1);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1); // MUI's TablePagination is 0-indexed
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Container>
      <Box mb={4} width="100%" display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
          <TextField
            variant="outlined"
            placeholder="Search clients..."
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch("")} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ flex: 1 }}
          />
        </Box>

        <RadioGroup
          row
          value={approvalFilter}
          onChange={handleApprovalFilterChange}
        >
          <FormControlLabel
            value="all"
            control={<Radio color="primary" />}
            label="All Clients"
          />
          <FormControlLabel
            value="approved"
            control={<Radio color="primary" />}
            label="Approved Only"
          />
          <FormControlLabel
            value="unapproved"
            control={<Radio color="primary" />}
            label="Unapproved Only"
          />
        </RadioGroup>
      </Box>

      {/* Client List Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Tests</TableCell>
              <TableCell>Validity</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : clients.length > 0 ? (
              clients.map((client:any) => (
                <TableRow key={client?._id}>
                  <TableCell>{client?.fullname ?? "N/A"}</TableCell>
                  <TableCell>{client?.email ?? "N/A"}</TableCell>
                  <TableCell>{client?.clientData?.isApproved ? "Yes" : "No"}</TableCell>
                  <TableCell>{client?.clientData?.numberOfUsers ?? "N/A"}</TableCell>
                  <TableCell>{client?.clientData?.numberOfAssessments ?? "N/A"}</TableCell>
                  <TableCell>{client?.clientData?.validity ? new Date(client.clientData.validity).toLocaleDateString() : "N/A"}</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/admin/client/update/${client?._id}`}
                      variant="contained"
                      color="primary"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold" sx={{ color: "white" }}>
                        Edit Client
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No clients found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" mt={4}>
        <TablePagination
          component="div"
          count={totalClients} // Use total client count from backend
          rowsPerPage={rowsPerPage}
          page={currentPage - 1} // MUI's TablePagination is 0-indexed
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 25, 50]}
          labelRowsPerPage="Clients per page"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
          }
        />
      </Box>
    </Container>
  );
};

export default ClientList;
