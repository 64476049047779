import { Box, FormControl, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";

interface McqProblemRendererProps {
  selectedQuestion: any;
  userMcqResponse: any[];
}

const McqProblemRenderer: React.FC<McqProblemRendererProps> = ({
  selectedQuestion,
  userMcqResponse,
}) => {
  const [currentResponse, setCurrentResponse] = useState<string>("");

  // Initialize and synchronize localStorage and state
  useEffect(() => {
    const storedResponses = JSON.parse(localStorage.getItem("userMcqResponse") || "[]");
    const questionResponse = storedResponses.find(
      (response: any) => response.mcqId === selectedQuestion._id
    );
    setCurrentResponse(questionResponse?.selectedOption || "");
    if (!storedResponses.length && userMcqResponse.length) {
      localStorage.setItem("userMcqResponse", JSON.stringify(userMcqResponse));
    }
  }, [selectedQuestion, userMcqResponse]);

  const handleOptionChange = useCallback((option: string) => {
    setCurrentResponse(option);
    const storedResponses = JSON.parse(localStorage.getItem("userMcqResponse") || "[]");
    const existingResponseIndex = storedResponses.findIndex(
      (response: any) => response.mcqId === selectedQuestion._id
    );
    if (existingResponseIndex !== -1) {
      storedResponses[existingResponseIndex].selectedOption = option;
    } else {
      storedResponses.push({ mcqId: selectedQuestion._id, selectedOption: option });
    }
    localStorage.setItem("userMcqResponse", JSON.stringify(storedResponses));
  }, [selectedQuestion]);

  return (
    <Box>
      <Paper
        sx={{
          backgroundColor: "background.default",
          padding: 2,
          borderRadius: 2,
          boxShadow: 3,
          marginBottom: 3,
        }}
        elevation={4}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "text.primary" }}>
          Question:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <div dangerouslySetInnerHTML={{ __html: selectedQuestion.question }} />
        </Typography>
      </Paper>
      <Paper
        sx={{
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "background.default",
        }}
        elevation={3}
      >
        <FormControl component="fieldset">
          <Typography variant="body1" sx={{ marginBottom: 3, fontWeight: "bold", color: "text.primary" }}>
            Select the correct answer:
          </Typography>
          <RadioGroup value={currentResponse} onChange={(e) => handleOptionChange(e.target.value)}>
            {selectedQuestion.options.map((option: string, idx: number) => (
              <label
                key={idx}
                htmlFor={`option-${idx}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 16,
                  padding: 16,
                  borderRadius: 12,
                  border: "1px solid transparent",
                  backgroundColor: "background.paper",
                  cursor: "pointer",
                  transition: "all 0.3s",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.borderColor = "#90caf9")}
                onMouseLeave={(e) => (e.currentTarget.style.borderColor = "transparent")}
              >
                <Radio
                  id={`option-${idx}`}
                  value={option}
                  sx={{
                    marginRight: 2,
                    color: "primary.main",
                    "&.Mui-checked": { color: "primary.dark" },
                  }}
                />
                <Typography variant="body1" sx={{ color: "text.primary", fontWeight: 500 }}>
                  <div dangerouslySetInnerHTML={{ __html: option }} />
                </Typography>
              </label>
            ))}
          </RadioGroup>
        </FormControl>
      </Paper>
    </Box>
  );
};

export default McqProblemRenderer;
