import * as React from "react";
import Box from "@mui/material/Box";
import { AppProvider, Router, Session } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Logo from "../Logo";
import { useUser } from '../../contextApi/UserContext';
import ProfileDashboard from "../../pages/Profile";
import UserManagement from "../../pages/admin/users/users";
import Problems from "../../pages/author/problem/problems";
import CreateProblem from "../../pages/author/problem/createProblem";
import CreateMCQ from "../../pages/author/mcq/createMcq";
import MCQs from "../../pages/author/mcq/problems";
import ReviewMcqs from "../../pages/moderator/ViewAllMcqs"
import ClientList from "../../pages/admin/client/ManageClients";
import CreateClient from "../../pages/admin/client/CreateClient";
import UpdateProblem from "../../pages/author/problem/updateProblem";
import UpdateClient from "../../pages/admin/client/UpdateClient";
import UpdateMCQ from "../../pages/author/mcq/updateMcq";
import { apiService } from "../../services/Service";
import { useGoogleLogin } from "@react-oauth/google";
import { getNavigationItems } from "./navigationConfig";
import ClientDetails from "../../pages/client/ClientDetails";
import ClientUserManagement from "../../pages/client/UserManagement";
import Assessments from "../../pages/assessment/Assessments";
import ClientAssessments from "../../pages/client/assessment/ClientAssessments";
import CreateAssessment from "../../pages/assessment/CreateAssessment";
import UpdateAssessment from "../../pages/assessment/UpdateAssessment";
import CreateUser from "../../pages/admin/users/CreateUser";
import CreatePlan from "../../pages/admin/plans/CreatePlan";
import ManagePlans from "../../pages/admin/plans/ManagePlans";
import UpdatePlan from "../../pages/admin/plans/UpdatePlan";
import UserGroupManagement from "../../pages/client/userGroupMangement";
import CreateUsers from "../../pages/client/CreateUsers";
import CreateGroupUsers from "../../pages/client/CreateGroupUsers";
import CreateUsersTabs from "../../pages/client/CreateUsersTabs";
import Tags from "../../pages/admin/tags/mcq/Tags";
import Createtags from "../../pages/admin/tags/mcq/Createtags";
import Updatetags from "../../pages/admin/tags/mcq/Updatetags";
import UserPage from "../../pages/admin/users/UserPage";
import ReviewMcq from "../../pages/moderator/ReviewMcq";
import AuthorContributions from "../../pages/admin/author/AuthorContributions";
function DemoPageContent({ pathname }: { pathname: string }) {
  // Match dynamic routes using useMatch for update pages
  const matchUpdateProblem = useMatch("/problem/update/:id");
  const matchUpdateClient = useMatch("/admin/client/update/:id");
  const mathchUserProfile = useMatch("/admin/user/:id");
  const matchUpdateMCQ = useMatch("/mcq/update/:id");
  const matchUpdateAssessment = useMatch("/assessments/update/:id");
  const matchUpdatePlan = useMatch("/plans/update/:id");
  const matchedMcqTags = useMatch("/mcq-tags/update/:id");
  const matchedReviewMcq = useMatch("/mcq/review/:id");

  return (
    <Box
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {(pathname === "/" && <ProfileDashboard />) ||
        (pathname === "/profile" && <ProfileDashboard />) ||
        (pathname === "/users" && <UserManagement />) ||
        (pathname === "/users/create" && <CreateUser />) ||
        (pathname === "/coding-problems/create" && <CreateProblem />) ||
        (pathname === "/coding-problems" && <Problems />) ||
        (pathname === "/mcq-problems/create" && <CreateMCQ />) ||
        (pathname === "/mcq-problems" && <MCQs />) ||
        (pathname === "/clients/create" && <CreateClient />) ||
        (pathname === "/clients" && <ClientList />) ||
        (pathname === "/client/details" && <ClientDetails />) ||
        (pathname === "/client/users" && <ClientUserManagement />) ||
        (pathname === "/client/assessments" && <Assessments />) ||
        (pathname === "/client/user-groups" && <UserGroupManagement />) ||
        (pathname === "/client/create-users" && <CreateUsersTabs />) ||
        (pathname === "/client/assessments/create" && <CreateAssessment />) ||
        (pathname === "/assessments" && <Assessments />)||
        (pathname === "/assessments/create" && <CreateAssessment />)||
        (pathname === "/plans" && <ManagePlans />)||
        (pathname === "/plans/create" && <CreatePlan />)||
        (pathname === "/mcq-tags" && <Tags />)||
        (pathname === "/mcq-tags/create" && <Createtags />)||
        (pathname === "/mcq-problems/review" && <ReviewMcqs />)||
        (pathname === "/work-tracker" && <AuthorContributions />)||
        

        

       
        
        // Render the appropriate component if a match is found
        (matchedMcqTags && <Updatetags />) ||
        (matchedReviewMcq && <ReviewMcq />) ||
        (mathchUserProfile && <UserPage />) ||
        (matchUpdatePlan && <UpdatePlan />) ||
        (matchUpdateProblem && <UpdateProblem />) ||
        (matchUpdateClient && <UpdateClient />) ||
        (matchUpdateMCQ && <UpdateMCQ />)||
        (matchUpdateAssessment && <UpdateAssessment />)}
    </Box>
  );
}

interface DemoProps {
  window?: () => Window;
}

export default function DashboardLayoutBasic(props: DemoProps) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useUser();
  const [session, setSession] = React.useState<Session | null>(null)
  const [navigationItems, setNavigationItems]: any = React.useState([]);
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      const response: any = await apiService.post("/auth/google", {
        token: tokenResponse.access_token,
      });
      localStorage.setItem("jwt_token", response.token);
      setSession({
        user: {
          name: response.user.fullname,
          email: response.user.email,
          image: response.user.picture,
        },
      });
      setLoading(false);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });
  const theme = useTheme(); // Access theme colors

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        googleLogin(); // Call the googleLogin function here
      },
      signOut: () => {
        localStorage.removeItem("jwt_token");
        setSession(null);
        navigate("/login");
      },
    };
  }, [googleLogin]);

  const router = React.useMemo<Router>(
    () => ({
      pathname: location.pathname,
      searchParams: new URLSearchParams(),
      navigate: (path: string | URL) => {
        const newPath = typeof path === "string" ? path : path.pathname;
        navigate(newPath);
      },
    }),
    [location.pathname, navigate]
  );

  const fetchProfile = async () => {
    try {
      const response: any = await apiService.get("/user/profile");
      setSession({
        user: {
          name: response.user.fullname,
          email: response.user.email,
          image: response.user.picture,
        },
      });
      setUser(response.user);
      if (response.user.role) {
        setNavigationItems(getNavigationItems(response.user.role));
      }
    } catch (err: any) {
      console.error("Error fetching profile data:", err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );

  return (
    <AppProvider
      navigation={navigationItems}
      router={router}
      theme={theme}
      branding={{ logo: <Logo />, title: "" }}
      authentication={authentication}
      session={session}
    >
      <DashboardLayout>
        <DemoPageContent pathname={location.pathname} />
      </DashboardLayout>
    </AppProvider>
  );
}
