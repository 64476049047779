import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { apiService } from "../../../services/Service"; // Replace with your API service file
import { UserRole } from "../../../utils/types";
import { useParams } from "react-router-dom";

interface ClientData {
  clientType: string;
  officialName: string;
  shortName: string;
  address: string;
  authorizedPersonName: string;
  authorizedContactNumber: string;
  designation: string;
  authorizedEmailId: string;
  officialEmailId: string;
  numberOfAssessments: number;
  numberOfUsers: number;
  validity: string;
}

interface User {
  fullname: string;
  email: string;
  role: string;
  clientData?: ClientData;
  permissions: string[];
}

const UserDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [sections, setSections] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchUserDetails = async () => {
    try {
      const response: any = await apiService.get(`/admin/user/${id}`); // Replace with actual user API endpoint
      setUser(response.user);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  const fetchSections = async () => {
    try {
      const response: any = await apiService.get("/tags/mcq/sections"); // Replace with the actual endpoint for fetching sections/tags
      console.log(response.data);
      setSections(response.data);
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([fetchUserDetails(), fetchSections()]);
      setLoading(false);
    })();
  }, []);

  const handlePermissionsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (user) {
      setUser({ ...user, permissions: event.target.value as string[] });
    }
  };

  const handleSavePermissions = async () => {
    try {
      await apiService.put(`/admin/user/${id}/permissions`, {
        permissions: user?.permissions,
      });
      alert("Permissions updated successfully");
    } catch (error) {
      console.error("Failed to save permissions:", error);
      alert("Failed to save permissions");
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Typography variant="h6" color="error" align="center">
        Failed to load user data.
      </Typography>
    );
  }

  return (
    <Box sx={{ mt: 4, px: 4 }}>
      <Typography variant="h4" gutterBottom>
        User Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Name:</Typography>
          <TextField value={user.fullname} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Email:</Typography>
          <TextField value={user.email} fullWidth disabled />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Role:</Typography>
          <TextField value={user.role} fullWidth disabled />
        </Grid>

        {user.role === UserRole.Client && user.clientData && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Official Name:</Typography>
              <TextField value={user.clientData.officialName} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Client Type:</Typography>
              <TextField value={user.clientData.clientType} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Address:</Typography>
              <TextField value={user.clientData.address} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Authorized Person:</Typography>
              <TextField value={user.clientData.authorizedPersonName} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Assessments:</Typography>
              <TextField value={user.clientData.numberOfAssessments.toString()} fullWidth disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Users Allowed:</Typography>
              <TextField value={user.clientData.numberOfUsers.toString()} fullWidth disabled />
            </Grid>
          </>
        )}

        {(user.role === UserRole.Moderator||user.role === UserRole.Author || user.role === UserRole.Client) && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="moderator-permissions-label">Select Permissions</InputLabel>
                <Select
                  labelId="moderator-permissions-label"
                  multiple
                  value={user?.permissions || []}
                  onChange={(e: any) => handlePermissionsChange(e)}
                >
                  {sections.map((section: any) => (
                    <MenuItem key={section._id} value={section.section}>
                      {section.section}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSavePermissions}
                fullWidth
              >
                Save Permissions
              </Button>
            </Grid>

          </>
        )}
      </Grid>
    </Box>
  );
};

export default UserDetailsPage;
