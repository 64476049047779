import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  TextField,
  Pagination,
  Typography,
  Button,
  ListSubheader,
  SelectChangeEvent,
} from "@mui/material";
import { apiService } from "../../services/Service";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../utils/types";
import { handleAuthError } from "../../utils/authUtils";
import { tagOptions } from "../../constants/tagOptions"; // Adjust if needed
import useDebounce from "../../customHooks/useDebounce";
import { CheckCircle } from "@mui/icons-material";

interface AddMqcsProps {
  addQuestion: (questionId: string, type: "mcq") => void;
  selectedQuestions?: string[]; // Optional prop for pre-selected questions
}

interface Section {
  section: string;
  tags: string[];
}

const AddMqcs: React.FC<AddMqcsProps> = ({ addQuestion, selectedQuestions = [] }) => {
  const [mcqs, setMcqs] = useState<any[]>([]); // Replace with your actual MCQ type
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [difficulty, setDifficulty] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedMcqs, setSelectedMcqs] = useState<string[]>(selectedQuestions); // Initialize with selectedQuestions
  const [page, setPage] = useState<number>(1); // Pagination state
  const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages
  const debouncedSearch = useDebounce(search, 500);
  const [tagOptions, setTagOptions] = useState<Section[]>([]);
  useEffect(() => {
    // Sync selectedProblems with selectedQuestions when prop changes
    setSelectedMcqs(selectedQuestions);
  }, [selectedQuestions]);

  useEffect(() => {
    const fetchMCQs = async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams({
          search: debouncedSearch,
          difficulty,
          tags: selectedTags.join(","),
          page: page.toString(),
          limit: "10",
        }).toString();

        const response = await apiService.get<{
          mcqs: any[]; // Replace with your MCQ type
          totalPages: number; // Total pages info from the API response
        }>(`/mcq?${params}`);
        setMcqs(response.mcqs);
        setTotalPages(response.totalPages);
      } catch (err) {
        setError("Failed to fetch MCQs");
        const error = err as AxiosError<ErrorResponse>;
        const errorResponse = error.response?.data;

        if (errorResponse) {
          handleAuthError({ error: errorResponse.error }, window.location.href);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMCQs();
  }, [debouncedSearch, difficulty, selectedTags, page]);

  const handleCheckboxChange = (questionId: string) => {
    setSelectedMcqs((prev) => {
      const isAlreadySelected = prev.includes(questionId);

      if (isAlreadySelected) {
        // Remove the question and notify parent to remove it
        addQuestion(questionId, "mcq"); // Ensure this properly removes in parent
        return prev.filter((id) => id !== questionId);
      } else {
        // Add the question and notify parent to add it
        addQuestion(questionId, "mcq");
        return [...prev, questionId];
      }
    });
  };


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleDifficultyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDifficulty(event.target.value as string);
  };

  const handleTagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTags(event.target.value as string[]);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };



  const fetchSections = () => {
    setLoading(true);
    apiService
      .get(`/tags/mcq/sections`) // Replace this endpoint with your actual backend endpoint
      .then((response: any) => {
        // Set the sections with their tags
        setTagOptions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSections();
  }, []);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box textAlign="center" color="error.main" mt={4}>
        {error}
      </Box>
    );

  return (
    <Box>
      <Box mb={2} display="flex" gap={2} flexWrap="wrap" alignItems="center">
        <TextField
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          label="Search"
          placeholder="Search by title"
          sx={{ flex: 1, minWidth: 200 }}
        />
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Difficulty</InputLabel>
          <Select
            value={difficulty}
            onChange={(e: any) => handleDifficultyChange(e)}
            label="Difficulty"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="easy">Easy</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="hard">Hard</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel>Tags</InputLabel>
          <Select
            name="tags"
            multiple
            value={selectedTags}
            onChange={(e: any) => handleTagChange(e)}
            renderValue={(selectedTags) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selectedTags as string[]).map((tag) => (
                  <Chip key={tag} label={tag} />
                ))}
              </Box>
            )}
            label="Tags"

          >
            {tagOptions.flatMap((section: Section) => [
              <ListSubheader key={`${section.section}-header`}>{section.section}</ListSubheader>,
              ...section.tags.map((tag: string) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>


      </Box>

      <Grid container spacing={2}>
        {mcqs.map((mcq) => (
          <Grid item xs={12} sm={6} md={4} key={mcq._id}>
            <Card
              sx={{
                height: 250,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: 3,
              }}
            >
              <CardContent
                sx={{
                  flex: 1,
                  maxHeight: 150,
                  overflowY: "auto",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    overflow: "auto",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: mcq.question }} />
                </Typography>
                <Box mt={1}>
                  <Chip label={mcq.difficultyLevel} color="primary" />
                  {mcq.tags.map((tag: string) => (
                    <Chip key={tag} label={tag} color="secondary" sx={{ ml: 1 }} />
                  ))}
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between", p: 1 }}>
                <Checkbox
                  checked={selectedMcqs.includes(mcq._id)}
                  onChange={() => handleCheckboxChange(mcq._id)}
                  color="primary"
                  icon={<CheckCircle color="disabled" />}
                  checkedIcon={<CheckCircle color="success" />}
                />
                <Button size="small" color="primary">
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default AddMqcs;
