import React from "react";
import { Box, Typography, Paper, Avatar, Divider } from "@mui/material";
import moment from "moment";

interface FollowUp {
  addedBy: {
    fullname: string;
  };
  comment: string;
  reason: string;
  timestamp: string; // Assuming timestamp is a string in ISO format
}

interface FormData {
  status: string;
  followUps: FollowUp[];
}

const FollowUpsSection = ({ formData }: { formData: FormData }) => {
  if (
    formData?.status === "rejected" &&
    formData?.followUps &&
    formData.followUps.length > 0
  ) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
          Follow-Ups
        </Typography>
        {formData.followUps.map((followUp, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              mb: 3,
              p: 3,
              borderRadius: 2,
              backgroundColor: "background.paper",
            }}
          >
            {/* Header Section */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>
                  {followUp.addedBy.fullname.charAt(0)}
                </Avatar>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", lineHeight: 1.2 }}
                  >
                    {followUp.addedBy.fullname}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    {moment(followUp.timestamp).format("MMM D, YYYY, h:mm A")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ mb: 2 }} />

            {/* Reason Section */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                mb: 1,
                color: "text.primary",
              }}
            >
              Reason for Rejection:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                mb: 2,
                wordBreak: "break-word",
              }}
            >
              {followUp.reason}
            </Typography>

            {/* Comment Section - Display only if not empty */}
            {followUp.comment && (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    color: "text.primary",
                  }}
                >
                  Additional Comment:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    wordBreak: "break-word",
                  }}
                >
                  {followUp.comment}
                </Typography>
              </>
            )}
          </Paper>
        ))}
      </Box>
    );
  }

  return null;
};

export default FollowUpsSection;
